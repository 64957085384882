import { combineReducers } from 'redux';

import user from './user';
import layout from './layout';
import proposals from './proposals';
import terms_of_use from './terms_of_use';
import messages from './messages';
import files from './files';
import policy from './policy';
import insurances from './insurances';
import insurance_infos from './insurance_info';
import certificates from './certificates';
import company from './company';
import gaspusers from './gaspusers';
import products from './products';
import partners from './partners'

const reducers = combineReducers({
  user,
  layout,
  proposals,
  terms_of_use,
  messages,
  files,
  policy,
  insurances,
  insurance_infos,
  certificates,
  company,
  gaspusers,
  products,
  partners,
});

export default reducers;
