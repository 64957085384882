import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { Creators } from '../../store/ducks/proposals';

let showedLivenessCheck = false;
let livenessComplete = false;
export default function Liveness() {
  const { livenessId } = useParams();
  const [message, setMessage] = useState('Carregando...');
  
  useEffect(() => {
    Creators.getLiveness(livenessId).then(data => {
      if (data.status === 'pendente') {
        initOzForensics(data);
      } else {
        setMessage('Link inválido! Favor, solicite um novo link ao corretor ou analista');
      }      
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livenessId]);

  const updateLiveness = (livenessData, ozforensic_folder_id, attempts) => {
    setTimeout(async () => {
      let attemptsText = '';
      if (attempts > 0) {
        attemptsText = ` (tentiva ${attempts+1})`;
      }
      setMessage(`Salvando${attemptsText}...\nPor favor não saia da página.`);
      const data = await Creators.updateLiveness(livenessData.id, { ozforensic_folder_id });
      if (data.error) {
        if (attempts < 9) {
          updateLiveness(livenessData, ozforensic_folder_id, ++attempts);
        } else {
          setMessage('Ops, não conseguimos processar sua análise.\nPor favor, tente novamente mais tarde.');
        }
      } else {
        setMessage('Tudo pronto!\nContinuaremos com sua análise e iremos retornar em breve.');
      }
    }, 2000);
  }

  const initOzForensics = (livenessData) => {
    setTimeout(() => {
      if (!showedLivenessCheck) {
        if (window.OzLiveness == null) {
          initOzForensics(livenessData);
        } else {
          setMessage('Aguardando prova de vida');
          window.OzLiveness.open({
            lang: 'pt-br',
            action: [
              //'photo_id_front', //photo of the ID front side;
              //'photo_id_back', //photo of the ID back side;
              //'video_selfie_left', //turn head to the left;
              //'video_selfie_right', //turn head to the right;
              //'video_selfie_down', //tilt head downwards;
              //'video_selfie_high', //raise head up;
              'video_selfie_blank', //no action, simple selfie;
              'video_selfie_smile', //smile;
              'video_selfie_eyes', //blink;
              //'video_selfie_scan', //scanning;
              'video_selfie_best', //special action to select the best shot from a video and perform analysis on it instead of the full video.
            ],
            meta: {
              // Your unique identifier that you can use later to find this folder in Oz API
              // Optional, yet recommended
              'transaction_id': livenessId,
              // You can add iin if you plan to group transactions by the person identifier
              'iin': 'gasp_proposal',
            },
            on_complete: async result => {
              livenessComplete = true;
              const ozforensic_folder_id = result?.folder_id;
              if (ozforensic_folder_id != null && result?.state === 'finished') {
                updateLiveness(livenessData, ozforensic_folder_id, 0);
              } else {
                setMessage('Ops, ocorreu algum problema.\nPor favor, tente novamente.');
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              }
            },
            on_close: result => {
              setTimeout(() => {
                if (!livenessComplete) {
                  window.location.reload();
                }
              }, 1000);
            }
          });
          showedLivenessCheck = true;
        }
      }
    }, 1000);
  }

  return (
    <Container>
      <Helmet>
        <script src="https://web-sdk.ohio.sandbox.ozforensics.com/longview/plugin_liveness.php?lang=pt-br"></script>
      </Helmet>
      <Row>
        <p 
          className="d-flex justify-content-center mt-5"
          style={{
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          }}
        >{message}</p>
      </Row>      
    </Container>
  );
}
