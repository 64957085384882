import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_PROPOSALS: 'proposals/GET_ALL',
  GET_PROPOSALS_SUCCESS: 'proposals/GET_ALL_SUCCESS',
  GET_PROPOSALS_ERROR: 'proposals/GET_ALL_ERROR',
  RESET: 'proposals/RESET',
  RESET_FILTER_INFO: 'proposals/RESET_FILTER_INFO',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
  filter: {},
  filter_info: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROPOSALS:
      return { ...state, loading: true };
    case actionTypes.GET_PROPOSALS_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload.data, 
        filter: action.payload.filter,
        filter_info: action.payload.filter_info 
      };
    case actionTypes.GET_PROPOSALS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, ...initialState };
      case actionTypes.RESET_FILTER_INFO:
        return { ...state, filter_info: initialState.filter_info };
    default:
      return state;
  }
}

export const Creators = {
  getProposals: (filter, filter_info, material_pendent, production) => async dispatch => {
    dispatch({ type: actionTypes.GET_PROPOSALS });
    try {
      filter = filter ?? {};
      filter_info = filter_info ?? {};
      filter['limit'] = '100';
      filter['user_type_id'] = 1;
      filter['is_gasp'] = 'true';
      if (material_pendent) {
        filter['material_pendent'] = 'true';
      }
      if (production) {
        filter['production'] = 'true';
      }
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.proposals}${filter_str}`);
      if (Object.keys(filter_info).length === 0) {
        const filter_info_data = await axios.get(`${Endpoints.proposals_info}`);
        if ((filter_info_data.data?.results?.length ?? 0) > 0) {
          filter_info = filter_info_data.data.results[0]; 
        }
      }
      dispatch({
        type: actionTypes.GET_PROPOSALS_SUCCESS,
        payload: { 
          data: data,
          filter: { ...filter },
          filter_info: filter_info
        }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: 'Não foi possível buscar as propostas no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getProposal: async (proposalId) => {
    try {
      const { data } = await axios.get(`${Endpoints.proposals}/${proposalId}`);
      return data;
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  exportResults: async (filter) => {
    try {
      const aFilter = {...filter ?? {}};
      if (aFilter['limit']) {
        aFilter['limit'] = undefined;
      }
      aFilter['user_type_id'] = 1;
      filter['is_gasp'] = 'true';
      const response = await axios({
        url: `${Endpoints.proposals_export}${stringifyFilters(aFilter)}`, 
        method: 'GET',
        responseType: 'blob', 
      });
      
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'propostas.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      return { error: 'Não foi possível exportar no momento' };
    }
  },
  getProposalNotes: async (proposalId) => {
    try {
      const { data } = await axios.get(`${Endpoints.proposal_notes}?proposal_id=${proposalId}&limit=999`);
      return { data };
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  createProposalNote: async (proposalId, analystId, text) => {
    try {
      const { data } = await axios.post(`${Endpoints.proposal_notes}`, {'proposal_id': proposalId, 'analyst_id': analystId, 'text': text});
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a observação. Por favor, tente novamente' };
    }
  },
  deleteProposalNote: async (noteId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.proposal_notes}/${noteId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a observação. Por favor, tente novamente' };
    }
  },
  getPendentCount: async () => {
    try {
      const { data } = await axios.get(Endpoints.proposals_count);
      const results = data.results ?? [{}];
      return results[0].material_pendent;
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  createProposal: async (fields) => {
    try {
      const { data } = await axios.post(`${Endpoints.proposals}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a proposta. Por favor, tente novamente' };
    }
  },
  updateProposal: async (proposalId, fields) => {
    try {
      delete fields.id;
      const { data } = await axios.patch(`${Endpoints.proposals}/${proposalId}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar a proposta. Por favor, tente novamente' };
    }
  },
  updateAnalyst: async (proposalId, analystId, isPhysical) => {
    try {
      const updateInfo = {};
      if (isPhysical) {
        updateInfo['physical_analyst_id'] = analystId;
      } else {
        updateInfo['analyst_id'] = analystId;

      }
      const { data } = await axios.patch(`${Endpoints.proposals}/${proposalId}`, updateInfo);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar a proposta. Por favor, tente novamente' };
    }
  },
  deleteProposal: async (proposalId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.proposals}/${proposalId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a proposta. Por favor, tente novamente' };
    }
  },
  resetProposals: () => ({ type: actionTypes.RESET }),
  resetProposalsFilterInfo: () => ({ type: actionTypes.RESET_FILTER_INFO }),
  getLiveness: async token => {
    try {
      const { data } = await axios.get(`${Endpoints.liveness}/${token}`);
      return data;
    } catch (err) {
      return { error: 'Ocorreu um erro. Por favor, tente novamente' };
    }
  },
  createLiveness: async (proposal_id, status, client_email) => {
    try {
      const { data } = await axios.post(Endpoints.liveness, {
        proposal_id,
        client_email,
        status,
        url_for_token: window.location.protocol + "//" + window.location.host + '/liveness',
      });
      return data;
    } catch (err) {
      return { error: 'Ocorreu um erro. Por favor, tente novamente' };
    }
  },
  updateLiveness: async (id, updateData) => {
    try {
      const { data } = await axios.patch(`${Endpoints.liveness}/${id}`, updateData);
      return data;
    } catch (err) {
      return { error: 'Ocorreu um erro. Por favor, tente novamente' };
    }
  },
  listLiveness: async (proposal_id, filters) => {
    try {
      const sFilter = stringifyFilters(filters ?? {})
      const { data } = await axios.get(`${Endpoints.proposals}/${proposal_id}/liveness${sFilter}`);
      return data?.results ?? [];
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar carregar a lista' };
    }
  },
};